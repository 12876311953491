import React from 'react';

import { Link } from 'gatsby';
import Layout from "../components/layout";
import {Helmet} from "react-helmet";

import { Title } from '../components/Globals';

// import 'react-awesome-slider/src/styles.js';
const partnersPage = () => {
    const [services] = React.useState([
        {
          id: 1,
          title: 'Driving value through COVID-19',
          desc: 'A peak inside our company culture and how we fulfill our clients’ demands.' 
        },
        {
          id: 2,
          title: 'Our people and our Commitments',
          desc: 'How we as best salesforce consulting partners are aiding the needs of our employees in these challenging times. ',
        },
        
      ]);
    
 
       return (
        <Layout>
      <Helmet>
        <title>Cymetrix Software | Salesforce Consultants India </title>
        <meta name="description" content="Collaborate with salesforce crm software implementation partners India. We aim at growing together with our strong partnership ecosystem."/>
        <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/partners/" /> 
      </Helmet>
    <div className="flex flex-col items-center pt-32">
      <main className="w-full flex flex-col items-center">
        <div className="max-w-5xl  mt-2">
            <div className="flex flex-col relative">
                <Title title="PARTNERS" uppercase classValue='text-gray-700 sma:text-base md:text-3xl my-5'/>
                <p className="block text-base xl:text-2xl  lg:text-lg md:text-base sm:text-base sma:text-base
        text-black text-uppercase text-center mb-5">
         Collaborations to power your enterprise <br />
         Great performance demands solid teamwork. We aim at growing together with our strong offshore software development services and reliable salesforce consulting partnership ecosystem.                                          
        </p>
            </div>
        </div>

        
      </main>
    </div>
    <div className="service-background w-full flex flex-col items-center lg:mb-8">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="INSIGHTS" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mx-20 mt-4 sm:mx-5 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">

                              </div>
                              <h1 className="mt-5 text-center ">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>
        <div className="flex flex-col relative">
               
                <Title title="Cymetrix CalendarInsight Application" uppercase classValue='text-gray-700 sma:text-base md:text-3xl'/>
               <p className="xl:text-2xl lg:text-lg md:text-base sm:text-base sma:text-base
        text-black text-uppercase text-center mb-5">A salesforce application developed by our Certified Salesforce Consultants.
        <Link to="/products" className="mt-5 text-hover text-lg text-center">Learn More</Link></p>
            </div>

            <div className="product-background w-full flex flex-col items-center" title="Background image of partners" alt="Partners Background  | Cymetrix Software">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Services</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Salesforce Consulting</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <Link to="/services/salesforce-consulting" className="mt-5 text-hover text-lg text-center">Learn More</Link>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Looker consulting</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <Link to="/services/looker-consulting" className="mt-5 text-hover text-lg text-center">Learn More</Link>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Spend Analysis</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <Link to="/services/spend-analysis" className="mt-5 text-hover text-lg text-center">Learn More</Link>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">IT security</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <Link to="/services/it-security" className="mt-5 text-hover text-lg text-center">Learn More</Link>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Salesforce Training </div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <Link to="/services/salesforce-training" className="mt-5 text-hover text-lg text-center">Learn More</Link>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Mulesoft Consulting</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <Link to="/services/mulesoft-consulting" className="mt-5 text-hover text-lg text-center">Learn More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="px-2 py-5">
            <Title title="PARTNERS"classValue={'text-black sma:text-xl text-4xl'} />
  <div class="flex -mx-2">
    <div class="w-1/5 px-2 ml-5">
      <div class="bg-gray-200 h-72 py-10">
      
      
      <img class="w-64 h-48  items-center mx-auto" src={require('../images/partners/salesforcepartner.png')} title="Salesforce Partner | Cymetrix Software" alt="Salesforce Partner Image | Cymetrix Software" />
      </div>
    </div>
    <div class="w-1/5 px-2">
      <div class="bg-gray-200 h-72  py-10">
      <img class="w-56 h-48 items-center mx-auto" src={require('../images/partners/salesforceorg.png')} title="Salesforce Registered Partners | Cymetrix Software" alt="Cymetrix Software - Registered partner 2020 Image" />
      </div>
    </div>
    <div class="w-1/5 px-2">
      <div class="bg-gray-200 h-72 py-10">
      <img class="w-56 h-48 items-center mx-auto" src={require('../images/partners/lookerconsultingpartner.png')} title="Looker Consulting Partner | Cymetrix Software" alt="Looker Consulting Partner Image - Cymetrix Software" />
      </div>
    </div>
    <div class="w-1/5 px-2 mr-5">
      <div class="bg-gray-200 h-72  py-10 ">
      <img class="w-56  h-48 items-center mx-auto" src={require('../images/partners/Jaggerpartner.png')} title="Cymetrix Software Partner | JAGGAER" alt="JAGGAER Software Company - Cymetrix Software's  Partner" />
      </div>
    </div>
    <div class="w-1/5 px-2 mr-5">
      <div class="bg-gray-200 h-72  py-10 ">
      <img class="w-56  h-48 items-center mx-auto" src={require('../images/partners/Fivetran.png')} title="Cymetrix Software Partner | Fivetran" alt="Cymetrix Software partners with Fivetran" />
      </div>
    </div>
  </div>
</div>
      
       
             </Layout>
  
);
}

export default partnersPage;
